
// Taken from https://github.com/Templarian/MaterialDesign/blob/master/svg/fan.svg
import {createSvgIcon} from "@mui/material";
import React from "react";

export const FanSpeedIcon = createSvgIcon(
    <path d="M12,11A1,1 0 0,0 11,12A1,1 0 0,0 12,13A1,1 0 0,0 13,12A1,1 0 0,0 12,11M12.5,2C17,2 17.11,5.57 14.75,6.75C13.76,7.24 13.32,8.29 13.13,9.22C13.61,9.42 14.03,9.73 14.35,10.13C18.05,8.13 22.03,8.92 22.03,12.5C22.03,17 18.46,17.1 17.28,14.73C16.78,13.74 15.72,13.3 14.79,13.11C14.59,13.59 14.28,14 13.88,14.34C15.87,18.03 15.08,22 11.5,22C7,22 6.91,18.42 9.27,17.24C10.25,16.75 10.69,15.71 10.89,14.79C10.4,14.59 9.97,14.27 9.65,13.87C5.96,15.85 2,15.07 2,11.5C2,7 5.56,6.89 6.74,9.26C7.24,10.25 8.29,10.68 9.22,10.87C9.41,10.39 9.73,9.97 10.14,9.65C8.15,5.96 8.94,2 12.5,2Z" />,
    "fanSpeed"
);

// Extracted from https://github.com/mqtt/mqttorg-graphics/blob/master/svg/mqtt-icon-solid.svg
export const MQTTIcon = createSvgIcon(
    <path
        d="M 20.094475,4.0709382 C 21.1373,5.1065709 22.151358,6.3507683 23,7.5086631 V 2.1003596 C 23,1.4962406 22.510951,1 21.89964,1 h -5.559333 c 1.294541,0.9061785 2.603466,1.9274273 3.754168,3.0709382 z M 22.992809,21.892448 V 14.405689 C 20.49003,8.1990848 15.398169,3.287022 9.0477281,1 H 2.1003596 C 1.4962406,1 1,1.4890487 1,2.1003596 V 3.1791435 C 11.974829,3.2438703 20.892776,12.097091 20.964695,23 h 0.934945 c 0.604119,-0.0072 1.093169,-0.496241 1.093169,-1.107552 z M 1,6.5377575 V 10.11932 C 8.1199739,10.184046 13.902256,15.923178 13.974175,23 h 3.718208 C 17.620464,13.909448 10.162471,6.5449494 1,6.5377575 Z M 1,13.477934 V 21.89964 C 1,22.503759 1.4890487,23 2.1003596,23 H 10.701864 C 10.629945,17.735535 6.3076169,13.485126 1,13.477934 Z"
    />,
    "mqtt"
);

// Extracted from https://static1.smartbear.co/swagger/media/assets/images/swagger_logo.svg
export const SwaggerUIIcon = createSvgIcon(
    <path
        d="m12.001 1c-6.065 0-11.001 4.935-11.001 11.001s4.935 10.999 11.001 10.999 10.999-4.933 10.999-10.999-4.934-11.001-10.999-11.001zm0 1.048c5.496 0 9.951 4.456 9.951 9.952s-4.455 9.951-9.951 9.951-9.952-4.455-9.952-9.951 4.456-9.952 9.952-9.952zm-3.326 4.331c-.133-.001-.261-.001-.388.006-.9.05-1.443.474-1.591 1.349-.106.609-.087 1.235-.13 1.848-.012.322-.046.639-.106.952-.122.558-.363.731-.93.761-.076.008-.148.021-.224.038v1.341c1.032.051 1.172.415 1.256 1.494.03.393-.013.787.012 1.18.016.372.067.741.144 1.101.237.985 1.198 1.315 2.361 1.117v-1.178c-.186 0-.35.004-.511 0-.394-.012-.542-.111-.58-.491-.051-.491-.039-.99-.069-1.485-.059-.918-.161-1.823-1.057-2.407.461-.338.795-.744.901-1.282.076-.376.123-.751.153-1.132.025-.381-.022-.771.012-1.147h.001c.056-.609.094-.858.826-.833.11 0 .215-.017.338-.025v-1.202c-.146 0-.283-.003-.416-.006zm6.958.008c-.231-.007-.48.012-.745.056v1.166c.224 0 .397.001.57.006.3.004.53.117.559.452.03.304.03.613.059.922.059.614.093 1.235.199 1.84.093.499.436.871.863 1.176-.748.503-.969 1.222-1.007 2.03-.021.554-.034 1.112-.063 1.671-.025.508-.203.673-.715.686-.144.004-.283.017-.444.025v1.197c.3 0 .576.017.851 0 .855-.051 1.37-.466 1.539-1.295.072-.457.115-.919.128-1.38.03-.423.026-.849.069-1.268.063-.656.362-.926 1.018-.969.063-.008.124-.021.183-.042v-1.342c-.11-.013-.187-.025-.267-.03v.001c-.491-.021-.736-.187-.859-.652-.076-.296-.123-.605-.139-.91-.034-.567-.03-1.137-.069-1.704-.072-1.093-.728-1.608-1.729-1.637zm-.931 4.839v.001c-.432-.008-.791.333-.804.765 0 .432.348.779.779.779h.008c.389.068.783-.309.808-.762.021-.419-.359-.783-.79-.783zm-5.384.001c-.423-.021-.787.308-.808.731-.021.427.308.791.731.812h.051c.419.025.778-.295.804-.714v-.043c.008-.427-.333-.778-.761-.787zm2.669 0c-.41-.013-.752.308-.765.714 0 .025 0 .047.004.072 0 .461.313.757.787.757.465 0 .757-.304.757-.783-.004-.461-.313-.765-.783-.761z"/>,
    "swagger"
);
