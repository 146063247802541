export const AboutText = `
Valetudo is made, managed, maintained and more by me,
<a href="https://hypfer.de" rel="noopener" target="_blank">Sören Beye</a>, with occasional contributions by
<a href="https://github.com/Hypfer/Valetudo/graphs/contributors" rel="noopener" target="_blank">these awesome people</a>.
It would not exist without the ongoing work of <a href="https://dontvacuum.me/" rel="noopener" target="_blank">Dennis Giese</a>, 
who is providing us with ways of liberating and gaining actual ownership of our robots.

Initially, the idea was to have a list of all open-source libraries and tools used in the making of Valetudo here,
however, it was quickly discovered that that would not be viable, as an exhaustive list including all transitive
dependencies contains thousands of projects.

We're all standing on the shoulders of giants. (<a href="https://xkcd.com/2347/" rel="noopener" target="_blank">XKCD 2347</a>)

### License

Valetudo is licensed under <a href="https://github.com/Hypfer/Valetudo/blob/master/LICENSE" rel="noopener" target="_blank">the Apache-2.0 license</a>,
but I will spare you the boring wall of text that you won't read anyways, as honestly, the license doesn't really matter that much.
All relevant open-source licenses basically boil down to "no warranty/no liability" and "don't act in bad faith" with various ways of trying to enforce the latter.

Unfortunately though, reality has shown us that actually doing so often isn't a feasible task.
People still either ignore the license agreement entirely or slyly try to find some way of working around it in a legally
acceptable but obviously incorrect way.

Thus, I might as well paste ten pages of lorem ipsum here.

Just be nice, please. Thank you :)
`;
